.best-price {
    background-image: url("../../img/best-price.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 64px;
    padding-bottom: 80px;
    position: relative;

    .--line-background {
        background-image: url("../../img/line 2.webp");
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: 60vw bottom;
        background-repeat: no-repeat;
    }


    .container {
        z-index: 2;
        position: relative;
    }

    &-title {
        font-size: var(--h1-size);
        font-family: var(--font-family-bahnschrift);
        margin-bottom: 40px;
        color: var(--white);
        text-transform: uppercase;
        line-height: 100%;
    }

    &-content {
        font-family: var(--font-family);
        display: flex;
        max-width: 690px;
        flex-flow: row wrap;

        &-elem {
            flex: 1 220px;
            @media(max-width: 991px) {
                flex: 1 140px;
            }
            display: flex;
            flex-flow: column;
            padding: 24px;
            gap: 8px;
            border: 1px solid var(--white);

            p {
                margin-bottom: 0;
                color: var(--white);
                font-size: 14px;
            }

            &:hover {
                background-color: var(--maslo-first);
            }
        }
    }

    @media(max-width: 991px) {

        padding-bottom: 0;

        .--line-background {
            display: block;
            position: relative;
            width: 100%;
            height: 275px;
            background-position: bottom right;
            background-size: contain;
        }

        &-content{
            padding-bottom: 80px;
        }
    }
}
