.gift-form {
    //background-image: url("./../../images/gift-form.webp");
    padding: 64px 0;

    margin-bottom: 120px;

    form {
        @media(max-width: 1200px) {
            margin-top: 24px;
            max-width: 500px;
        }

        .--input {
            min-width: unset;
        }
    }

    &-gift {
        padding: 24px;
        display: flex;
        flex-flow: column;
        min-height: 412px;
        width: 100%;
        @media(max-width: 768px) {
            max-width: 250px;
        }
        border: solid 1px var(--blue-grey-50);

        &.--button {
            color: var(--white) !important;
        }

        &.--active {
            background-color: rgba(255, 255, 255, 0.2);

            .--button {
                background-color: var(--maslo-first);

                svg {
                    display: initial;
                }

                .--active {
                    display: initial;
                }

                .--default {
                    display: none;
                }
            }
        }

        &-image {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 24px;
            min-height: 200px;
        }

        &-block {
            margin-bottom: 0;
            margin-top: auto;
        }

        &-title {
            font-size: var(--h3-size);
            font-family: var(--font-family-bahnschrift);
            margin-bottom: 1.5rem;
            text-transform: uppercase;
        }

        .--button {
            width: 100%;

            svg {
                margin-right: 8px;
                display: none;
            }

            .--active {
                display: none;
            }
        }
    }
}

.credit-form {
    margin-bottom: 120px;

    &-content {
        padding: 40px;
        background-color: var(--grey-50);
        //background-image: url("../../images/credit.webp");
        background-position: bottom right;
        background-repeat: no-repeat;
    }


    &-variants {
        display: flex;
        flex-flow: column;
        gap: 16px;

        &-variant {
            display: flex;
            border: solid 1px var(--blue-grey-50);
            padding: 26px;
            gap: 24px;
            align-items: center;

            &:hover {
                border-color: var(--maslo-first);
            }

            .--size {
                font-weight: bold;
                font-family: var(--font-family-bahnschrift);
                font-size: var(--h1-size);
            }

            .--button {
                svg {
                    display: none;
                }

                .--active {
                    display: none;
                }
            }

            &.--active {
                border-color: var(--maslo-first);
                .--button {
                    background-color: var(--maslo-first);
                    color: white !important;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    svg {
                        display: initial;
                    }

                    .--default {
                        display: none;
                    }

                    .--active {
                        display: initial;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        &-content {
            //background-image: unset;
            padding-bottom: 350px;
        }
        &-variants {
            &-variant {
                gap: 8px 24px;
                flex-flow: row wrap;
                justify-content: space-between;

                .--text {
                    font-weight: bold;
                }
            }
        }
    }

    .--subtext {
        color: var(--blue-grey-900);
    }

    &-form {
        max-width: 616px;

        .--inputs {
            margin-top: 24px;
            display: flex;
            gap: 16px;
            flex-flow: row wrap;

            & > * {
                flex: 1;
            }
        }
    }
}
