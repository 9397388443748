header {
    font-family: var(--font-family-bahnschrift);
    margin-top: 60px;

    .header-content {
        background-size: cover;
        background-position: center;
        padding-top: 48px;
        min-height: 100px;
        padding-bottom: 450px;
        background-image: url('../../img/head_background.webp');


        h1 {
            color: white;
            font-size: var(--title1-size);
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
            //max-width: 700px;

        }

        h3 {
            color: white;
            font-size: var(--h3-size);
            font-weight: 400;
            text-align: center;
            text-transform: uppercase;
        }

        @media (max-width: 991px) {
            h1 {
                font-size: var(--title3-size) !important;
            }
        }
    }

}
