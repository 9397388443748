.service-block {
    margin-bottom: 50px;
    .section-title {

    }

    &-elem {
        background-color: #FFE27B;
        border: solid 1px #D9B638;
        padding: 1.5rem;
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        &-title {
            text-transform: uppercase;
            font-size: 1.5rem;
            font-family: var(--font-family-bahnschrift);
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
        .--transparent_button{
            background-color: white;
        }
    }
}
