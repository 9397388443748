.footer-form {
  margin: 120px 0;

  &-content {
    padding: 40px;
    background-color: var(--grey-50);

    .--title {
      margin-bottom: 8px;
    }

    .--subtitle-text {
      margin-bottom: 40px;
      font-size: var(--h4-size);
    }

    .--subtext {
      color: var(--blue-grey-900);
    }

    form{
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
    }

    &-address{
      &-title{
        font-family: var(--font-family-bahnschrift);
        font-size: var(--h2-size);
        margin-bottom: 24px;
        text-transform: uppercase;
        font-weight: bold;
      }
      &-elems{
        display: flex;
        flex-flow: column;
        gap: 12px;
        &-elem{
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
  }
  @media(max-width: 576px){
    margin: 48px 0;
    &-content{
      padding: 16px;
      .row{
        gap: 16px;
        form{
          input{
            max-width: unset;
            width: 100%;
          }
          button{
            width: 100%;
          }
        }
      }
    }
  }
}