.about-block{
  //padding: 40px;
  background-color: var(--blue-grey-900);
  &-content{
    max-width: 690px;
    .--title{
      margin-bottom: 8px;
    }
    padding: 40px;
    @media(max-width: 576px){
      padding: 24px;
    }
    &-text{
      margin-bottom: 80px;
    }
    &-counter{
      display: flex;
      gap: 24px;
      flex-flow: row wrap;
      &-elem{
        flex: 0 184px;
        @media(max-width: 576px){
          flex: 0 170px;
        }
        display: flex;
        flex-flow: column;
        gap: 8px;
        color: white;
        .--big{
          font-size: var(--h1-size);
          font-family: var(--font-family-bahnschrift);
        }
      }
    }
  }
}
