@import "typography";
@import "bootstrap";
@import "vars";

$white_color: '#fff';
$font-size: 1rem;
$font-color: lch(28 99 35);

html {
    font-size: $font-size;
    color: var(--blue-grey-900);
    font-family: var(--font-family);
    font-style: normal;
}

.container {
    max-width: var(--content-width) !important;
}

@import "components/elements";
@import "components/nav";
@import "components/header";
@import "components/catalog";
@import "components/logistic";
@import "components/delivery";
@import "components/services";
@import "components/best_price";
@import "components/buy_schema_special_block_wrranty_block";
@import "components/gift_credit";
@import "components/about";
@import "components/footer_form";
@import "components/footer";
@import "components/product";

/*
display: grid;
gap: 32px;
grid-template-columns: 1fr 1fr;*/

.--color-text {
    color: var(--maslo-first) !important;
}

.--white {
    color: var(--white) !important;
}

.navbar-toggler {
    padding: 0 !important;
    border: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
}

@keyframes fade {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}

#productForm,
#gift-form-block,
#how-buy,
#special-block,
#footer-form,
#about-block,
#credit-form-block {
    scroll-margin: 65px;
}

.--sw-custom-popup {
    .swal2-html-container {
        font-family: var(--font-family-bahnschrift);
        text-transform: uppercase;
        font-weight: bold;
    }

    .swal2-actions {
        .swal2-confirm {
            background-color: var(--maslo-first) !important;
            border: unset !important;
            box-shadow: unset !important;
        }
    }
}

.--sw-custom {
    border: unset !important;
    padding: 8px !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;

    .--send-content-icon {
        margin: 0 !important;
    }

}
