.buy-schema {
  margin-top: 120px;


  &-content {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;

    &-elem {
      flex: 0 306px;
      @media(max-width: 678px){
        flex: 1;
      }
      padding: 24px;
      background-color: var(--grey-50);

      &-title {
        font-family: var(--font-family-bahnschrift);
        font-size: var(--h3-size);
        margin-bottom: 1rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      &-text {
        font-size: 14px;
        margin-bottom: 0;
      }
        button{
            margin-top: 1rem;
        }
    }
  }
}

.special-block {
  font-family: var(--font-family-bahnschrift);
  margin-top: 120px;
  //background-image: url("../../images/special.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-bottom: 52px;

  &-content {
    display: flex;
    flex-flow: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-offer {
    font-size: var(--h3-size);
    text-transform: uppercase;
    font-weight: bold;
    background-color: white;
    padding: 1rem;
    margin-bottom: 8px;
  }
}

.warranty-block {
  padding: 120px;


  &-content {
    background-color: var(--grey-50);
    padding: 40px;

    &-image {
      background-image: url("../../img/doc 1.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      min-width: 300px;
      min-height: 300px;
      @media (max-width: 991px) {
        background-position: top center;
      }
    }

    &-text {
      &-columns {
        display: flex;
        flex-flow: row wrap;
        gap: 24px;
      }
      &-column {
        display: flex;
        flex-flow: column;
        gap: 24px;
        flex: 1;

        &-elem {
          position: relative;
          padding-left: 16px;
          font-size: var(--h4-size);

          &:before {
            content: " ";
            width: 8px;
            height: 8px;
            position: absolute;
            left: 0;
            top: 8px;
            background-color: var(--maslo-first);
          }
        }
      }
    }
  }

  @media(max-width: 991px){
    padding: 24px 0;
    &-content{
      padding: 16px;
    }
  }
}
