.--button {
    font-size: 1rem;
    line-height: 150%;
    font-family: var(--font-family-bahnschrift);
    background-color: white;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.--base_button {
    color: var(--white) !important;
    border: none;
    background-color: var(--maslo-first) !important;

    &:hover {
        background-color: var(--maslo-second) !important;
    }

    &:disabled {
        background-color: var(--blue-grey-100) !important;
    }
}

.--transparent_button {
    color: var(--blue-grey-900) !important;
    border: 1px solid var(--blue-grey-50);
    background-color: unset;

    &:hover, &.--active {
        border-color: var(--maslo-second) !important;
    }

    &:disabled {
        border-color: var(--blue-grey-50) !important;
        color: var(--blue-grey-100) !important;
    }
}

.--icon_button {
    border: none;
    color: var(--blue-grey-900) !important;
    background-color: var(--maslo-first) !important;

    &:hover {
        background-color: var(--maslo-second) !important;
    }

    &:disabled {
        background-color: var(--blue-grey-100) !important;
        color: white !important;
    }
}

.--switch_button {
    border: solid 1px var(--blue-grey-50);
    color: var(--blue-grey-900);
    border-radius: 100px;
    padding: 4px 14px;
    background-color: var(--white);
    cursor: pointer;

    &.--active {
        border: solid 1px var(--maslo-first);
    }

    &:disabled, &.--disabled {
        border: solid 1px var(--blue-grey-50);
        color: var(--blue-grey-100);
    }
}

.--link {
    color: var(--blue-grey-900);
    font-size: 1rem;
    line-height: 150%;
    font-family: var(--font-family-bahnschrift);

    &.--roboto {
        font-family: var(--font-family);
    }

    font-weight: 600;

    &.--light {
        font-weight: 400;
    }

    text-decoration: none;

    &:hover {
        color: var(--maslo-first);

        svg {
            path {
                fill: var(--maslo-first);
            }

            rect {
                stroke: var(--maslo-first);
            }
        }
    }

    &:disabled {
        color: var(--blue-grey-100);
    }

}

.breadcrumb {
    --bs-breadcrumb-padding-y: .75rem;

    &-item {
        color: var(--blue-grey-200);
        font-size: 14px;
        padding: 0 16px;
        font-family: var(--font-family);

        &:last-child {
            color: var(--blue-grey-900);
        }

        & + .breadcrumb-item {
            padding: 0 16px;
            position: relative;

            &:before {
                width: 2px;
                height: 2px;
                background-color: var(--maslo-first);
                content: "";
                font-size: 0;
                line-height: 0;
                padding: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }
}

.--input {
    font-family: var(--font-family-bahnschrift);
    padding: 8px 16px;
    border-color: var(--blue-grey-50) !important;
    font-size: 1rem;
    border: solid 1px;
    color: var(--blue-grey-200);
    line-height: 150%;
    min-width: 300px;

    &:focus {
        border-color: var(--blue-grey-900) !important;
        color: var(--blue-grey-900);
    }

    &:not([value=""]) {
        color: var(--blue-grey-900);
    }

    &:focus-visible {
        outline: unset;
    }

    &:disabled {
        color: var(--blue-grey-50);
        border-color: var(--blue-grey-50) !important;
    }

    &:invalid {
        color: var(--maslo-first);
        border-color: var(--maslo-first) !important;
    }
}

.fluid-form {
    background-image: url("../../img/head_form_background.webp");

    &.--product {
        .fluid-form-content {
            background-image: unset;
        }

        .fluid-form-col {
            display: flex;
            align-items: center;
            gap: 50px;
            /* margin: 0 auto; */
            justify-content: center;
            flex-flow: row wrap;
        }

        @media (max-width: 991px) {
            .fluid-form-price {
                padding-top: 24px;
            }
            .fluid-form-content {
                padding-top: 12px;
            }
            .fluid-form-col {
                gap: 12px;
            }
        }
    }

    &-price {
        font-family: var(--font-family-bahnschrift);
        display: flex;
        flex-flow: column;

        &-old {
            font-size: 32px;
            color: white;
            display: flex;
            gap: 8px;
            align-items: center;

            .--discount {
                font-size: 24px;
                background-color: #FFCA28;
                color: var(--blue-grey-900);
                padding: 8px 12px;
                border-radius: 100px;
                text-decoration: none;
                line-height: 100%;
            }
        }

        &-current {
            font-size: 4rem;
            color: #FFCA28;
        }
    }

    &-content {
        padding: 46px 0;
        background-image: url("../../img/head_form_image.webp");
        background-position: right center;
        background-repeat: no-repeat;

        &-text {
            color: var(--white);
            font-size: var(--h3-size);
            text-transform: uppercase;
            letter-spacing: -1px;
            display: inline-block;
            margin-bottom: 0.5rem;
        }

    }

    &-inputs {
        display: flex;
        gap: 1rem;
        flex-flow: row wrap;

        .--input {
            width: 100%;
            max-width: 300px;
        }
    }

    @media(max-width: 991px) {
        &-content {
            background-image: unset;
        }
        &-inputs {
            .--input {
                width: 100%;
                max-width: initial;
            }

            .--button {
                width: 100%;
            }
        }
    }
}

.section-title {
    font-size: var(--h1-size);
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: bold;
}

.--title {
    font-size: var(--h1-size);
    color: var(--blue-grey-900);
    margin-bottom: 40px;
    line-height: 110%;
    text-transform: uppercase;
    font-weight: bold;
    font-family: var(--font-family-bahnschrift);
}

.--subtext {
    color: var(--white);
    font-size: var(--small-text-size);
    font-family: var(--font-family);
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: 0;

    a {
        text-decoration: underline;
        color: var(--blue-grey-200);
    }
}

.--close-button {
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_176_1425)"><path d="M12 10.5361L19.5 3.00017L20.9141 4.42096L13.414 11.9569L21 19.5792L19.586 21L12 13.3776L4.41401 21L3 19.5792L10.586 11.9569L3.08595 4.42078L4.49996 3L12 10.5361Z" fill="%23263238"/></g><defs><clipPath id="clip0_176_1425"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
    width: 24px;
    height: 24px;
    border: unset;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    background-color: unset;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.--send-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &-icon {

        width: 40px;
        height: 40px;
        background-size: cover;
        background-position: center;
        margin-bottom: 1rem;

        &.error {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z" style="&%2310; fill: %23F4511E;&%2310;"/></svg>');
        }

        &.success {
            background-image: url('data:image/svg+xml,<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_176_1468)"><path d="M20.4997 37.1666C11.2947 37.1666 3.83301 29.705 3.83301 20.5C3.83301 11.295 11.2947 3.83331 20.4997 3.83331C29.7047 3.83331 37.1663 11.295 37.1663 20.5C37.1663 29.705 29.7047 37.1666 20.4997 37.1666ZM18.838 27.1666L30.6213 15.3816L28.2647 13.025L18.838 22.4533L14.123 17.7383L11.7663 20.095L18.838 27.1666Z" fill="%238BC34A"/></g><defs><clipPath id="clip0_176_1468"><rect width="40" height="40" fill="white" transform="translate(0.5 0.5)"/></clipPath></defs></svg>');
        }
    }

    &-message {
        font-size: 2rem;
        text-transform: uppercase;
        font-family: var(--font-family-bahnschrift);
    }
}

.modal {
    --bs-modal-border-radius: 0;
    --bs-modal-padding: 0;

    &.--big-modal {
        --bs-modal-width: 750px;

        .modal-dialog {
            max-width: 750px;
        }
    }

    &-dialog {

    }

    &-content {

    }

    &-body {
        padding: 0;
        display: flex;
        @media(max-width: 576px) {
            justify-content: center;
        }

        &-image {
            width: 387px;
            background-size: cover;
            background-position: center;
            @media(max-width: 576px) {
                display: none;
            }
        }

        &-form {
            padding: 40px;
            max-width: 390px;
            min-height: 455px;
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

            &-title {
                font-size: var(--h2-size);
                font-family: var(--font-family-bahnschrift);
                margin-bottom: 1rem;
                text-transform: uppercase;
                line-height: 110%;
            }


            &-text {
                margin-bottom: 12px;
                font-size: 0.87rem;
            }

            form {
                display: flex;
                flex-flow: column;
                gap: 12px;

                input {
                    min-width: unset;
                    width: 100%;
                }
            }

            .--subtext {
                color: var(--blue-grey-900);
            }

            .--continue {
                font-size: var(--h4-size);
                text-transform: uppercase;
                font-family: var(--font-family-bahnschrift);
                margin-top: 32px;
                color: var(--blue-grey-900);
                text-decoration: none;
                border: none;
                background-color: unset;
                text-align: center;
                width: 100%;
            }

            @media (max-width: 576px) {
                padding: 24px;
                max-width: unset;
                min-height: unset;
            }
        }
    }
}

.splide {
    padding: 0 !important;
}
