// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
    // base
    --font-family: "Roboto", sans-serif;
    --font-family-bahnschrift: "Bahnschrift", sans-serif;
    --bs-body-font-family: "Roboto", sans-serif;

    --content-width: 1320px;
    --container-offset: 15px;
    --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

    // colors
    --light-color: #ffffff;
    --gray-color: rgba(255, 255, 255, 0.5);
    --ebony-color: #0F172A;
    --pickled-color: #334155;
    --slate-color: #64748B;
    --gull-color: #94A3B8;
    --geyser-color: #CBD5E1;
    --catskill-color: #E2E8F0;
    --persian-color: #1E40AF;
    --royal-color: #2563EB;
    --dodger-color: #3B82F6;
    --lochmara-color: #0284C7;
    --cerulean-color: #0EA5E9;
    --alizarin-color: #DC2626;
    --flamingo-color: #EF4444;
    --lightning-color: #FBBF24;
    --jewel-color: #15803D;
    --salem-color: #16A34A;
    --white: #ffffff;
    --deep-orange-600: #F4511E;
    --deep-orange-900: #BF360C;
    --blue-grey-900: #263238;
    --blue-grey-800: #37474F;
    --blue-grey-200: #B0BEC5;
    --blue-grey-300: #90A4AE;
    --blue-grey-400: #78909C;
    --blue-grey-100: #D8DCCF;
    --blue-grey-50: #ECEFF1;
    --grey-50: #FAFAFA;
    --ambar-600: #FFB300;
    --ambar-500: #FFC107;
    --maslo-first: #D9B638;
    --maslo-second: #b6982f;

    --title1-size: 72px;
    --title2-size: 56px;
    --title3-size: 40px;
    --h1-size: 40px;
    --h2-size: 32px;
    --h3-size: 24px;
    --h4-size: 16px;
    --small-text-size: 12px;

}

@keyframes fade {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}
