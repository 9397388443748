.navbar {
    --bs-navbar-padding-y: 0.75rem;
    position: fixed;
    background-color: white !important;
    top: 0;
    width: 100%;
    z-index: 3;

    .navbar-brand {
        --bs-navbar-brand-margin-end: 2.5rem;
        @media (max-width: 991px) {
            --bs-navbar-brand-margin-end: 0;
            svg:last-child {
                display: none;
            }
        }
    }

    @media (max-width: 991px) {
        .navbar-collapse {
            border-top: 1px solid var(--blue-grey-50);
            margin-top: 12px;
            padding-top: 24px;
        }
    }


    .navbar-nav {
        flex: 1;

        @media(min-width: 991px) {
            flex-flow: row wrap;
        }

        .nav-item {
            &.dropdown {
                .dropdown-menu {
                    border: unset;
                    @media (max-width: 992px) {
                        display: block;
                    }
                }

                .dropdown-toggle {
                    @media (max-width: 992px) {
                        &:after {
                            display: none;
                        }
                    }
                    @media (min-width: 992px) {
                        padding-right: 1rem !important;
                    }

                    &.show {
                        &:after {
                            transform: translateY(-50%) rotate(0deg);
                        }
                    }

                    &:after {
                        border: unset;
                        background-image: url('data:image/svg+xml,<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.67711 5.47075C9.41676 5.7311 8.99465 5.7311 8.7343 5.47075L5.20571 1.94216L1.67711 5.47075C1.41676 5.7311 0.994655 5.7311 0.734305 5.47075C0.473955 5.2104 0.473955 4.78829 0.734305 4.52794L4.7343 0.527945C4.99465 0.267595 5.41676 0.267595 5.67711 0.527945L9.67711 4.52794C9.93746 4.78829 9.93746 5.2104 9.67711 5.47075Z" fill="%23263238"/></svg>');
                        width: 9px;
                        height: 5px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            .nav-link {
                font-size: 14px;
                text-transform: uppercase;
                font-family: var(--font-family);
                color: var(--blue-grey-900);
                font-weight: 400;

                &:hover {
                    color: var(--maslo-first);
                }

            }
        }
    }

    .nav-buttons {
        display: flex;
        gap: 1.5rem;
        @media (max-width: 991px) {
            gap: 12px;
            a {
                color: var(--maslo-first);
                font-weight: bold;
            }
        }

        .nav-phone {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            font-weight: 400;

            &-text {
                text-transform: uppercase;
                font-family: var(--font-family);
            }
        }
    }
}
