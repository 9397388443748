.delivery-block{
    background-color: var(--blue-grey-900);
    padding: 60px 0;
    .section-title{
        font-size: 2.5rem;
        margin-bottom: 40px;
        text-align: center;
        color: white;
    }
    &-elems{
        //gap: 40px;
    }
    &-elem{
        margin-bottom: 24px;
        background-color: white;
        padding: 1.5rem;
        display: grid;
        grid-template-columns: 1fr 2fr;
        @media (max-width: 590px) {
            grid-template-columns: 1fr;
        }
        gap: 24px;
        &-img{
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            min-height: 100px;
        }
        &-content{
            &-title{
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 1rem;
                font-family: var(--font-family-bahnschrift);
            }
            &-content{
                margin-bottom: 1.5rem;
                font-size: 1rem;

            }
        }
    }
}
