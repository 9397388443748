.product-description {
    margin-bottom: 120px;

    &-gallery{
        padding: 60px 0 120px 0;
    }

    &-big-spec {
        padding: 60px 0;
        display: flex;
        flex-flow: row wrap;
        gap: 24px;
        justify-content: space-between;

        &-elem {
            display: flex;
            flex: 1;
            flex-flow: column;
            justify-content: center;
            gap: 8px;
            align-items: center;
            position: relative;

            span:first-child {
                font-size: 14px;
            }

            span:last-child {
                line-height: 100%;
                font-family: var(--font-family-bahnschrift);
                font-size: var(--title3-size);
            }

            & + .product-description-big-spec-elem {
                padding: 0 16px;
                position: relative;

                &:before {
                    width: 5px;
                    height: 5px;
                    background-color: var(--maslo-first);
                    content: "";
                    font-size: 0;
                    line-height: 0;
                    padding: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -12px;
                }
            }
        }
    }

    &-spec {
        img {
            max-width: 100%;
        }

        &-list {
            display: flex;
            flex-flow: column;
            gap: 24px;

            &-elem {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                gap: 6px;
                animation: fade .5s ease-in-out;

                &.--hidden {
                    display: none;
                }

                .--line {
                    border-bottom: solid 1px var(--blue-grey-100);
                    flex: 1;
                    margin-bottom: 6px;
                }

                span:first-child {
                    font-size: 1rem;
                    font-weight: 600;
                }

                span:last-child {
                    font-size: 1rem;
                    text-align: right;
                }
            }

            margin-bottom: 40px;
        }

        .--show-more {
            font-size: 1rem;
            color: var(--blue-grey-200);
            cursor: pointer;
            position: relative;
            &:after{
                content: " ";
                background-image: url('data:image/svg+xml,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_218_10274)"><path d="M8.50048 8.78132L11.8005 5.48132L12.7431 6.42399L8.50048 10.6667L4.25781 6.42399L5.20048 5.48132L8.50048 8.78132Z" fill="%23B0BEC5"/></g><defs><clipPath id="clip0_218_10274"><rect width="16" height="16" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>');
                width: 17px;
                height: 17px;
                background-position: center;
                background-size: contain;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            &.--opened{
                &:after{
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }

    &-adventure {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 370px);
        grid-gap: 32px 24px;
        gap: 32px 24px;
        justify-content: center;

        &--elem {
            display: flex;
            flex-flow: column;
            gap: 8px;

            .--elem-image {
                min-height: 260px;
                background-size: cover;
                background-position: center;

            }

            .--elem-title {
                font-size: 1.5rem;
                font-family: var(--font-family-bahnschrift);
                font-weight: 900;
            }

            .--elem-text {
                font-size: 1rem;
            }
        }
    }
}
