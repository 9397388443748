.logistic-block {
    &-content {
        font-family: var(--font-family-bahnschrift);

        .section-title {
            font-size: 2.5rem;
            margin-bottom: 0.25rem;
            text-align: center;
        }

        &-small{
            border: solid 3px var(--maslo-first);
            padding: 40px;
            margin: -40px;
            @media (max-width: 1420px) {
                padding: 24px;
                margin: 0;
            }
        }

        .section-subtitle {
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 40px;
        }

        table {
            width: 100%;
            font-size: 1rem;
            thead {
                tr {
                    th {
                        font-weight: bold;
                        padding: 0.5rem;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
}
