.catalog {
    padding: 80px 0;
    font-family: var(--font-family-bahnschrift);

    &-filter {
        display: flex;
        gap: 24px;
        flex-flow: column;

        &-row {
            display: flex;
            gap: 24px;
            @media(max-width: 991px) {
                flex-flow: column;
            }
        }

        &-block {
            &-title {
                font-size: 14px;
                margin-bottom: 6px;
            }

            &-buttons {
                /*&-button {
                  input:checked + label{
                    border: solid 1px var(--deep-orange-600);
                  }
                }*/
                display: flex;
                gap: 6px;
                flex-flow: row wrap;

                button {
                    margin-bottom: 8px;
                }
            }
        }
    }

    &-products {
        margin-top: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        &-load-more {
            margin-top: 24px;
        }

        .row {
            /* --bs-gutter-x: -1rem;
             --bs-gutter-y: 1rem;*/
        }
    }

    &-product-card {
        justify-content: center;
    }

    &-product {
        border: solid 1px var(--blue-grey-50);
        padding: 24px;
        display: flex;
        flex-flow: column;
        margin-bottom: 24px;

        &-image {
            min-height: 150px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 24px;
            &.--small{
                min-height: 75px;
            }
        }

        &-title {
            font-size: var(--h3-size);
            text-transform: uppercase;
            margin-bottom: 24px;
            font-weight: bold;
            line-height: 110%;

        }

        &-data-block {
            margin-bottom: 0;
            margin-top: auto;
        }

        &-spec {
            font-family: var(--font-family);
            margin-bottom: 24px;

            &-row {
                display: flex;
                justify-content: space-between;
                padding: 8px;

                &:nth-child(2n+1) {
                    //background-color: var(--blue-grey-50);
                    border: solid 1px var(--blue-grey-50);
                    border-left: unset;
                    border-right: unset;
                }

                span {
                    font-size: var(--h4-size);
                }
            }
        }

        &-price {
            font-family: var(--font-family);
            font-size: var(--h3-size);
            color: var(--maslo-first);
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 8px;

            .--old {
                text-decoration: line-through;
                font-size: var(--h4-size);
                color: var(--blue-grey-400);
                font-weight: normal;

            }
        }

        &-buttons {
            display: flex;
            flex-flow: column;
            gap: 0.5rem;
        }
    }
}
