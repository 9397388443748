.footer {
  background-color: var(--blue-grey-900);
  padding: 32px 0;

  &-number{
    padding: 16px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    .--icon_button{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }

  &-link{
    font-size: 0.875rem;
    color: var(--white);
    text-transform: uppercase;
    text-decoration: none;
      &:hover{
          color: var(--maslo-first) !important;
      }
  }
  &-req{
    font-size: 0.875rem;
    color: var(--white);
    text-decoration: none;
  }
  &-politics{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    gap: 12px 24px;
    a{
      color: #90A4AE;
      font-size: 0.875rem;
      text-decoration: none;
    }
  }

  &-menu {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 12px 24px;
    margin-bottom: 12px;
    align-items: center;
    @media(max-width: 576px){
      flex-flow: column;
    }
  }

  &-contacts {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 12px 32px;
    align-items: center;
    @media(max-width: 576px){
      flex-flow: column;
    }
  }
  &-company{
    margin-top: 12px;
    text-align: center;
  }
  &-politics{
    margin-top: 32px;
    border-top: solid 1px #37474F;
    padding-top: 32px;

  }
}
